import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import {
  ProfileCardHeader,
  LabelBox,
  ProfileCardPaper,
  maskPhone,
} from '@fdha/web-ui-library';

interface PatientProfileCardProps {
  name: string;
  email?: string;
  phoneNumber: string;
}

const ProfileCard: FC<PatientProfileCardProps> = ({
  name,
  email,
  phoneNumber,
}) => {
  return (
    <ProfileCardPaper>
      <ProfileCardHeader title={name || ''} />
      <Box>
        <Grid container rowSpacing={0} columnSpacing={10}>
          <LabelBox
            size={6}
            label="Phone number"
            value={maskPhone(phoneNumber, false)}
          />
          <LabelBox size={6} label="Email" value={email} />
        </Grid>
      </Box>
    </ProfileCardPaper>
  );
};

export default ProfileCard;
